.search-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  min-height: 100vh;
  height: 100%;
  background-color: #5499c9;
  overflow: scroll;

  >div {
    >header {
      padding: 1rem;
      color: white;
      display: flex;
      gap: 1rem;
      cursor: pointer;

      > span {
        font-size: 40px;
      }

      >div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        h3, h5 {
          margin: 0px;
        }
      }
    }

    >.tabel-section {
      width: max-content;
      margin: auto;
      margin-top: 10%;

      table {
        border-radius: 0.5rem;
        overflow: hidden;
        border: 1px solid lightgrey;
      }

      .tbl-header{
        background-color: rgba(255,255,255,0.3);
        >table {
          width: 100%;
        }
       }
      .tbl-content{
        height:max-content;
        overflow-x:auto;
        margin-top: 0px;
        border: 1px solid rgba(255,255,255,0.3);
        background: white;
      }
      th{
        padding: 20px 15px;
        text-align: left;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
      }
      td{
        padding: 15px;
        text-align: center;
        vertical-align:middle;
        font-weight: 700;
        font-size: 12px;
        color: #000;
        border-bottom: solid 1px rgba(255,255,255,0.1);
      }
    }

    >.ticket-container {
      display: flex;
      align-items: flex-start;
  
      &.ticket-list {
        width: 100%;
        max-width: 600px;
        margin: 1rem auto;
        justify-content: space-between;
      }

      >div {
        &.tickets {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
        
        .ticket-card {
          display: flex;
          max-width: 100vw;
          flex-direction: column;
          gap: 8px;
          background-color: white;
          border-radius: 0.5rem;
          border: 1px solid lightgrey;
          padding: 1rem;
      
          >header {
            padding: 0.5rem 0px;
            display: flex;
            flex-direction: column;
            h2, h4 {
              margin: 0px;
            }
      
            h2 {
              color: black;
              font-weight: 800;
              font-size: 24px;
            }
      
            h4 {
              color: lightslategray;
              font-weight: 400;
              font-size: 16px;
            }
      
            img {
              width: 100px;
              margin: auto;
      
              &.budiman {
                width: 150px;
              }
            }
      
            .qr-section {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
      
              p {
                margin: 0px;
                font-size: 14px;
              }
            }
          }
      
          .penumpang {
            tr {
              td {
                font-weight: 700;
                font-size: 14px;
                &:first-child {
                  width: 40%;
                }
      
                &.invoice {
                  padding-top: 15px;
                }
      
                &.sm {
                  font-weight: 400;
                }
              }
            }
          }
      
          >section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            >section {
              h3 {
                margin: 0px;
                font-weight: 700;
                font-size: 14px;
                padding: 4px 3px;
      
                &.title {
                  color: grey;
                  font-size: 8px;
                  font-weight: 400;
                }
      
                &.bus-code {
                  font-weight: 400;
                }
              }
      
              &.row {
                display: flex;
                // gap: 2rem;
      
                >section {
                  min-width: 50%;
      
                  >table {
                    width: 100%;
                    border: 1px solid;
                    >tr {
                      >td {
                        width: 50%;
                        border: 1px solid;
                      }
                      h3 {
                        margin: 0px;
                        font-weight: 700;
                        font-size: 14px;
                        padding: 4px;
              
                        &.title {
                          color: grey;
                          font-size: 8px;
                          font-weight: 400;
                        }
                      }
                    }
                  }
                }
              }
            }
      
            >table {
              border: 1px solid;
              width: 100%;
              >tr {
                >th, td {
                  border: 1px solid;
                }
                h3 {
                  margin: 0px;
                  font-weight: 700;
                  font-size: 14px;
                  padding: 4px;
        
                  &.title {
                    color: grey;
                    font-size: 8px;
                    font-weight: 400;
                  }
                }
              }
            }
          }
      
          .divider-container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            
            .divider {
              height: 0px;
              width: 90%;
              border: 1px dashed lightgrey;
            }
            
            .circle {
              position: absolute;
              width: 3rem;
              height: 3rem;
              background-color: #5398C9;
              border-radius: 100%;
              left: -3rem;
      
              &.right {
                right: -3rem;
                left: auto;
              }
            }
          }
      
        }
      }

      .button-section {
        display: flex;
        width: max-content;
        height: max-content;
        justify-content: space-between;
        .button-container {
          width: max-content;
          display: flex;
          justify-content: end;
          border: none;
          background-color: #187AA4;
          gap: 8px;
          padding: 16px;
          border-radius: 8px;
          cursor: pointer;
  
          &:active {
            background-color: #0e4c67;
  
            span {
              color: rgb(115, 114, 114);
            }
  
            div {
              h3, h5 {
                color: rgb(115, 114, 114);
              }
            }
          }
      
          span {
            color: white;
            font-size: 32px;
          }
      
          div {
            display: flex;
            flex-direction: column;
            gap: 8px;
            h3, h5 {
              color: white;
              margin: 0px;
            }
          }
          
        }
      }
    }
  }


  .search-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .search-card {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      padding: 30px 2rem;
      margin: 1rem;
      background: #F5F5F5;
      border-radius: 25px;
      width: max-content;
      height: max-content;
      min-width: 521px;
      max-height: 210px;
      position: relative;
  
      header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
  
        div {
          text-align: center;
        }
   
        h2 {
          margin: 0px;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
        }
  
        h4 {
          margin: 0px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 27px;
        }
  
        h2 {
          color: #000000;
        }
      }
  
      input {
        height: 30px;
        width: calc(100% - 60px);
        border: none;
        filter: drop-shadow(0px 0px 1px black);
        border-radius: 0.5rem;
        font-size: 14px;
        font-weight: 600;
        padding: 4px 8px;
        margin: auto;
    
        &::placeholder {
          color: lightgray;
          font-size: 14px;
          font-weight: 600;
        }
      }
  
      >h5 {
        width: calc(100% - 60px);
        margin: 0px auto;
      }
  
      >h4 {
        margin: 0px;
        color: salmon;
      }
  
      img {
        width: 142px;
        height: 44px;
        margin: auto 0px;
      }
  
      .footer-container {
        display: flex;
        width: calc(100% - 60px);
        margin: auto;
        justify-content: space-between;
        .button-container {
          width: max-content;
          display: flex;
          justify-content: end;
          border: none;
          background-color: #187AA4;
          gap: 8px;
          padding: 16px;
          border-radius: 8px;
          margin: 16px 0px;
          cursor: pointer;
  
          &:active {
            background-color: #0e4c67;
  
            span {
              color: rgb(115, 114, 114);
            }
  
            div {
              h3, h5 {
                color: rgb(115, 114, 114);
              }
            }
          }
      
          span {
            color: white;
            font-size: 32px;
          }
      
          div {
            display: flex;
            flex-direction: column;
            gap: 8px;
            h3, h5 {
              color: white;
              margin: 0px;
            }
          }
          
        }
      }
      
    }
  }
}

.button-print {
  cursor: pointer;
  padding: 0.5rem;
  background-color: #187AA4;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media only screen and (max-width: 425px) {
  .ticket-card {
    min-width: 200px !important;
    overflow: hidden;

    >header {
      padding: 1rem 0px;
      display: flex;
      flex-direction: column;
    }

  }
}